import $ from 'jquery';
import { gsap } from 'gsap';

const BREAKPOINT = 767;
let isMobileView = window.matchMedia(`(max-width: ${BREAKPOINT}px)`).matches;
const config = {
	timing: {
		fadeInDuration: 1,
		fadeOutDuration: 1,
		taglineDelay: "-=0.1",
		scene1Delay: 0.1,
		sceneDelay: 2.1,
		finalSceneDelay: 0.3,
		illustDelay: 1,
	},
	ease: "power2.out",
	initialState: {
		opacity: 0
	},
	initialFilterState: {
		opacity: 0,
		filter: "blur(20px) grayscale(1) brightness(1.5)",
		scale: 1.05
	},
	animateIn: {
		opacity: 1,
		duration: 1.5,
		ease: "power2.out"
	},
	animateOut: {
		opacity: 0,
		duration: 1,
		ease: "power2.out"
	},
	initialSceneAnimation: {
		opacity: 1,
		filter: "blur(0px) grayscale(0) brightness(1)",
		scale: 1,
		duration: 1.5,
		ease: "power2.out"
	},
	// イラストのアニメーション設定
	illustAnimation: {
		opacity: 1,
		duration: 1.8,
		ease: "power2.out"
	},
	// bgのマスクアニメーション設定
	bgMaskAnimation: {
		opacity: 1,
		maskPosition: "0% 0%",
		duration: 2,
		ease: "power3.out"
	},
	// taglineのアニメーションパターン
	taglineAnimations: {
		// 文字のぼかし＆明るさ
		textEffect: {
			initial: {
				opacity: 0,
				filter: "blur(10px) brightness(1.5)"
			},
			animate: {
				opacity: 1,
				filter: "blur(0px) brightness(1)",
				duration: 1,
				ease: "power2.out"
			}
		}
	}
};

// デバウンス関数
function debounce(func, wait) {
	let timeout;
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

function setInitialStates() {
	gsap.set(".scene02, .scene03, .scene04", config.initialState);
	gsap.set(".scene01 .tagline, .scene02 .tagline, .scene03 .tagline, .scene04 .tagline", config.initialState);
	gsap.set(".scene01 .bg", config.initialState);
	gsap.set(".scene01", config.initialFilterState);
	// デバイスに応じて初期状態を設定
	if (window.matchMedia("(max-width: 768px)").matches) {
		// SP用の設定
		gsap.set(".scene01 .illust_sp01, .scene01 .illust_sp02, .scene01 .illust_sp03, .scene01 .illust_sp04", { opacity: 0 });
		gsap.set(".scene01 .illust_container > div", { display: 'none' });
		gsap.set(".scene01 .illust_sp01, .scene01 .illust_sp02, .scene01 .illust_sp03, .scene01 .illust_sp04", { display: 'block' });
	} else {
		// PC用の設定
		gsap.set(".scene01 .illust_container > div", { opacity: 0 });
		gsap.set(".scene01 .illust_container > div", { display: 'block' });
		gsap.set(".scene01 .illust_sp01, .scene01 .illust_sp02, .scene01 .illust_sp03, .scene01 .illust_sp04", { display: 'none' });
	}
	// taglineの初期状態を設定
	gsap.set(".scene01 .tagline, .scene02 .tagline, .scene03 .tagline, .scene04 .tagline",
		config.taglineAnimations.textEffect.initial
	);
}

function resetAllScenes() {
	gsap.set(".scene01, .scene02, .scene03, .scene04", config.initialState);
	gsap.set(".scene01 .tagline, .scene02 .tagline, .scene03 .tagline, .scene04 .tagline", config.initialState);
	gsap.set(".scene01 .bg", config.initialState);
	gsap.set(".scene01 .illust_container > div", config.initialState);
	gsap.set(".scene01 .illust_sp01, .scene01 .illust_sp02, .scene01 .illust_sp03, .scene01 .illust_sp04", config.initialState);
}

function startAnimation() {
	setInitialStates();
	playSequence();
}

function restartAnimation() {
	// 実行中のアニメーションをすべて停止
	gsap.killTweensOf("*");
	// シーンをリセット
	resetAllScenes();
	// アニメーションを再開
	startAnimation();
}

function checkBreakpoint() {
	const currentIsMobileView = window.matchMedia(`(max-width: ${BREAKPOINT}px)`).matches;

	// ブレイクポイントを跨いだ場合のみリセット
	if (currentIsMobileView !== isMobileView) {
		isMobileView = currentIsMobileView;
		restartAnimation();
	}
}

function playLastScene(masterTimeline) {
	// デバイス幅の判定
	const isSP = window.matchMedia("(max-width: 768px)").matches;

	if (isSP) {
		// スマートフォン用のアニメーション
		masterTimeline
			.to(".scene01", config.animateIn)
			.set(".scene01 .illust_sp01", { opacity: 1 }, "<")
			.to(".scene01 .illust_sp02", config.illustAnimation, "+=0.3")
			.to(".scene01 .illust_sp03", config.illustAnimation, `-=${config.illustAnimation.duration - config.timing.illustDelay}`)
			.to(".scene01 .illust_sp04", config.illustAnimation, `-=${config.illustAnimation.duration - config.timing.illustDelay}`)
			// BGとタグラインのアニメーション
			.to(".scene01 .tagline", config.taglineAnimations.textEffect.animate)
			.to(".scene01 .bg", config.bgMaskAnimation, config.timing.taglineDelay);
	} else {
		// PC用のアニメーション
		masterTimeline
			.to(".scene01", config.animateIn)
			.set(".scene01 .illust01", { opacity: 1 }, "<")
			.to(".scene01 .illust02", config.illustAnimation, "+=0.3")
			.to(".scene01 .illust03", config.illustAnimation, `-=${config.illustAnimation.duration - config.timing.illustDelay}`)
			.to(".scene01 .illust04", config.illustAnimation, `-=${config.illustAnimation.duration - config.timing.illustDelay}`)
			.to(".scene01 .illust05", config.illustAnimation, `-=${config.illustAnimation.duration - config.timing.illustDelay}`)
			.to(".scene01 .tagline", config.taglineAnimations.textEffect.animate)
			.to(".scene01 .bg", config.bgMaskAnimation, config.timing.taglineDelay);
	}
}


function playSequence() {
	const masterTimeline = gsap.timeline();
	// デバイス幅の判定
	const isSP = window.matchMedia("(max-width: 768px)").matches;

	masterTimeline
		// シーン1
		.to(".scene01", config.initialSceneAnimation);

	if (isSP) {
		// スマートフォン用の初期イラストアニメーション
		masterTimeline.to(".scene01 .illust_sp01, .scene01 .illust_sp02, .scene01 .illust_sp03, .scene01 .illust_sp04", {
			opacity: 1,
			duration: config.initialSceneAnimation.duration,
			ease: config.initialSceneAnimation.ease
		}, "<");
	} else {
		// PC用の初期イラストアニメーション
		masterTimeline.to(".scene01 .illust_container > div", {
			opacity: 1,
			y: 0,
			duration: config.initialSceneAnimation.duration,
			ease: config.initialSceneAnimation.ease
		}, "<");
	}

	masterTimeline
		.to(".scene01 .tagline", config.taglineAnimations.textEffect.animate, config.timing.taglineDelay)
		.to(".scene01 .tagline", config.taglineAnimations.textEffect.animate)
		.to({}, { duration: config.timing.scene1Delay })

		// シーン2
		.to(".scene02", config.animateIn)
		.to(".scene01, .scene01 .tagline, .scene01 .illust_container > div, .scene01 .illust_sp", config.animateOut, "<")
		.to(".scene02 .tagline", config.taglineAnimations.textEffect.animate, config.timing.taglineDelay)
		.to({}, { duration: config.timing.sceneDelay })

		// シーン3
		.to(".scene03", config.animateIn)
		.to(".scene02, .scene02 .tagline", config.animateOut, "<")
		.to(".scene03 .tagline", config.taglineAnimations.textEffect.animate, config.timing.taglineDelay)
		.to({}, { duration: config.timing.sceneDelay })

		// シーン4
		.to(".scene04", config.animateIn)
		.to(".scene03, .scene03 .tagline", config.animateOut, "<")
		.to(".scene04 .tagline", config.taglineAnimations.textEffect.animate, config.timing.taglineDelay)
		.to({}, { duration: config.timing.sceneDelay })

		// シーン4をフェードアウト
		.to(".scene04, .scene04 .tagline", config.animateOut)
		.to({}, { duration: config.timing.finalSceneDelay });

	// 最後のシーンを実行
	playLastScene(masterTimeline);
}

const handleResize = debounce(() => {
	checkBreakpoint();
}, 300);

// イベントリスナーの設定
window.addEventListener('load', () => {
	if (document.getElementById('mainImgWrapper')) {
		startAnimation();
	}
});
window.addEventListener('resize', () => {
	if (document.getElementById('mainImgWrapper')) {
		handleResize();
	}
});