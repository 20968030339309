import $ from 'jquery';

$(function() {
	if($("#form").length){
		if($(".error").length > 0){
			let top = $(".error").eq(0).parents("tr,div").offset().top;
			top -= 90;
			if (window.matchMedia("(max-width: 768px)").matches) {
				top -= 110;
			}
			$("html,body").animate({ scrollTop: top }, 0);
		}

		$(".back_button").on("click", function(){
			$("input[name='back']").val(1);
			return;
		});
	}
});