/**
 * メガメニューの制御を行う関数
*/
function initMegaMenu() {
  const body = document.body;
  const blsBG = document.querySelector('#blsBG');

  // ホバー処理
  const navLinks = document.querySelectorAll('.group-header__navLink');
  navLinks.forEach(link => {
    const parentItem = link.closest('.group-header__navItem');
    // SP版のアコーディオン要素はスキップ
    if (parentItem.querySelector('.js-details')) return;

    link.addEventListener('mouseenter', () => {
      link.classList.add('is_current');
    });
    link.addEventListener('mouseleave', () => {
      link.classList.remove('is_current');
    });
  });

  // メガメニュー処理
  const megamenus = document.querySelectorAll('[data-megamenu]');
  megamenus.forEach(menu => {
    const megamenuNav = document.querySelector(`[data-megamenu-nav="${menu.dataset.megamenu}"]`);
    const navLink = menu.querySelector('.group-header__navLink');

    const updateMegamenuState = (isOpen = false) => {
      // 他のメガメニューを閉じる
      megamenus.forEach(menu => {
        const megamenuNav = document.querySelector(`[data-megamenu-nav="${menu.dataset.megamenu}"]`);
        const navLink = menu.querySelector('.group-header__navLink');
        megamenuNav.classList.remove('is_active');
        navLink.classList.remove('is_current');
      });

      if (isOpen) {
        megamenuNav.classList.add('is_active');
        navLink.classList.add('is_current');
        body.classList.add('is_fixed');
      } else {
        body.classList.remove('is_fixed');
      }
    };

    navLink.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      updateMegamenuState(!megamenuNav.classList.contains('is_active'));
    });

    // 背景クリックでの閉じる処理
    blsBG.addEventListener('click', (e) => {
        updateMegamenuState(false);
    });
  });
}

/**
 * ハンバーガーメニューの制御を行う関数
*/
function initHamburgerMenu() {
  const hamburgerButton = document.querySelector('.js-blsHamburgerButton');
  const hamburgerContents = document.querySelector('.js-blsHamburgerContents');
  const header = document.querySelector('.js-header');
  const buttonText = document.querySelector('.js-hamburgerButtonText');

  hamburgerButton.addEventListener('click', () => {
    const isActive = hamburgerContents.classList.contains('is_active');
    hamburgerContents.classList.toggle('is_active');
    header.classList.toggle('is-open');
    buttonText.textContent = !isActive ? '閉じる' : '開く';
  });
}


document.addEventListener('DOMContentLoaded', function() {
  if (document.getElementById('header')) {
    initMegaMenu()
    initHamburgerMenu();
  }
});