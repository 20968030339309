import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

$(function() {
	gsap.utils.toArray('.scrollAnm').forEach((item) => {
		const position = item.dataset.position;

		ScrollTrigger.create({
			trigger: item,
			start: "top " + (position || "70%"),
			toggleClass: "is-animation",
			once: true,
			//markers: true,
		});
	});
});