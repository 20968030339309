import $ from 'jquery';

//320px以下のデバイスはビューポートの幅を320pxにする
if(document.documentElement.clientWidth <= 320){
	$('meta[name="viewport"]').attr('content', 'width=320, user-scalable=yes');
}

let hoverTouch = function(elm,func,func2){
	$(document).on("touchstart mouseenter",elm,function(e){
		if(e.type == "mouseenter" && $(this).attr("data-touched")){
			return;
		}
		func.apply(this,e)
	});
	$(document).on("touchend touchcancel mouseleave",elm,function(e){
		if(e.type == "touchend" || e.type == "touchcancel"){
			const $this = $(this);
			$this.attr("data-touched",1);
			setTimeout(function(){
				$this.removeAttr("data-touched");
			},500);
		}
		func2.apply(this,e)
	});
}

$(function() {
	hoverTouch("a,button,.hover",function(){
		$(this).addClass("hovered");
	},function(){
		$(this).removeClass("hovered");
	});

	hoverTouch("a.over,button.over",function(){
		$(this).stop(false,true).animate({opacity:0.7},200);
	},function(){
		$(this).stop(false,true).animate({opacity:1},200);
	});

	hoverTouch("img.over",function(){
		if(!$(this).attr("data-src-off")){
			$(this).attr("data-src-off", $(this).attr("src"));
		}
		$(this).attr("src", $(this).attr("data-src-on"));
	},function(){
		$(this).attr("src", $(this).attr("data-src-off"));
	});

	$(document).on("click","a.scroll",function(e){
		e.preventDefault();
		const target  = $(this).attr('href');
		const targetY = target == "#" ? 0 : $(target).offset().top;
		window.scrollTo({
			top: targetY - 90,
			left: 0,
			behavior: "smooth",
		});
		return false;
	});
});