import { gsap } from 'gsap';
// detailsに.focus_nextを付与した場合はページ内スクロールが付与

const setUpModalAccordion = () => {
    const accordions = document.querySelectorAll(".sustainability_accordion");
    const IS_OPENED_CLASS = "is-opened";
    
    // アニメーション設定
    const animations = {
        close: (content) => gsap.to(content, {
            height: 0,
            opacity: 0,
            duration: 0.4,
            ease: "power3.out",
            overwrite: true
        }),
        
        open: (content) => gsap.to(content, {
            height: "auto",
            opacity: 1,
            duration: 0.4,
            ease: "power3.out",
            overwrite: true
        })
    };
    
    // 各アコーディオン要素にイベントリスナーを設定
    accordions.forEach(accordion => {
        const accordionBtn = accordion.querySelector(".accordion_btn");
        const hiddenContent = accordion.querySelector(".hidden_contents");
        
        if (!accordionBtn || !hiddenContent) return;
        hiddenContent.style.height = "0";
        hiddenContent.style.opacity = "0";
        
        const handleInteraction = () => {
            const isOpened = accordion.classList.toggle(IS_OPENED_CLASS);
            animations[isOpened ? 'open' : 'close'](hiddenContent);
        };

        accordionBtn.addEventListener('click', handleInteraction);
    });
};

// detailsに.focus_nextを付与した場合はページ内スクロールが付与
const setUpDetailsAccordion = () => {
    const details = document.querySelectorAll(".js-details");
    const IS_OPENED_CLASS = "is-opened";
    details.forEach((element) => {
        const summary = element.querySelector(".js-summary");
        const content = element.querySelector(".js-content");
        summary.addEventListener("click", (event) => {
            event.preventDefault();
            if (element.classList.contains(IS_OPENED_CLASS)) {
                element.classList.toggle(IS_OPENED_CLASS);
                closingModalAnim(content, element).restart();
            } else {
                element.classList.toggle(IS_OPENED_CLASS);
                element.setAttribute("open", "true");
                openingModalAnim(content).restart();

                if (element.classList.contains('focus_next')) {
                    setTimeout(() => {
                        content.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }, 200);
                }
            }
        });
    });
}

const closingModalAnim = (content, element) => gsap.to(content, {
    height: 0,
    opacity: 0,
    duration: 0.4,
    ease: "power3.out",
    overwrite: true,
    onComplete: () => {
        element.removeAttribute("open");
    },
});

const openingModalAnim = (content) => gsap.fromTo(
    content,
    {
        height: 0,
        opacity: 0,
    },
    {
        height: "auto",
        opacity: 1,
        duration: 0.4,
        ease: "power3.out",
        overwrite: true,
    }
);

// DOMの読み込み完了後に初期化
document.addEventListener('DOMContentLoaded', () => {
    setUpModalAccordion();
    setUpDetailsAccordion();
});