import Cookies from 'js-cookie';
import $ from 'jquery';

let isEnableGoogleAnalytics = false;
function enableGoogleAnalytics(){
	if(!isEnableGoogleAnalytics){
		isEnableGoogleAnalytics = true;

		if(location.hostname == "www.tnbls.co.jp"){
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());

			gtag('config', 'G-T1RK8S6253');
		}
	}
}

$(function(){
	const isCookieAllow = Cookies.get('isCookieAllow');
	if(isCookieAllow){
		if(isCookieAllow == "true"){
			enableGoogleAnalytics();
		}
	}else{
		$("#cookieBanner").show();
	}

	$("#cookieAllowBtn").on('click', function(){
		Cookies.set("isCookieAllow", "true", { expires: 365 });
		$("#cookieBanner").hide();
		enableGoogleAnalytics();
	});

	$("#cookieDenyBtn").on('click', function(){
		Cookies.set("isCookieAllow", "false", { expires: 365 });
		$("#cookieBanner").hide();
	});

	$("#cookieSettingBtn").on('click', function(){
		Cookies.remove("isCookieAllow");
		$("#cookieBanner").show();
	})
});